import React, { useState } from 'react'
import axios from 'axios' // Import axios for making HTTP requests
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CToast,
  CToastBody,
  CToaster,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import { useNavigate } from 'react-router-dom'

const Login = () => {
  const navigate = useNavigate()
  // State variables for email, password, and error message
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')

  const [toastMessage, setToastMessage] = useState('')
  const [toast, setToast] = useState(false)

  // Validation function
  const validateForm = () => {
    if (!email.trim() || !password.trim()) {
      setError('Email and password are required.')
      return false
    }
    return true
  }

  // Submit function
  const handleSubmit = async (e) => {
    e.preventDefault()
    setError('')
    if (validateForm()) {
      try {
        const response = await axios.post(`https://api-dev.u4u.pw/admin/users/login`, {
          email: email,
          password: password,
        })
        if (response.data.status) {
          setToastMessage(response.data.message)
          setToast(true)
          setTimeout(() => {
            setToast(false)
            localStorage.setItem('token', response.data.data)
            localStorage.setItem('user', JSON.stringify(response.data.user))
            navigate(`/dashboard`)
          }, 3000)
        } else {
          setError('Invalid email or password.')
        }
      } catch (error) {
        setError('An error occurred. Please try again later.')
      }
    }
  }

  return (
    <>
      {toast && (
        <CToaster placement="top-end">
          <CToast autohide={false} visible={toast}>
            <CToastBody>{toastMessage}</CToastBody>
          </CToast>
        </CToaster>
      )}
      <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md={8}>
              <CCardGroup>
                <CCard className="p-4">
                  <CCardBody>
                    <CForm onSubmit={handleSubmit}>
                      <h1>Login</h1>
                      <p className="text-medium-emphasis">Sign In to your account</p>
                      <CInputGroup className="mb-3">
                        <CInputGroupText>
                          <CIcon icon={cilUser} />
                        </CInputGroupText>
                        <CFormInput
                          placeholder="Email"
                          autoComplete="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </CInputGroup>
                      <CInputGroup className="mb-4">
                        <CInputGroupText>
                          <CIcon icon={cilLockLocked} />
                        </CInputGroupText>
                        <CFormInput
                          type="password"
                          placeholder="Password"
                          autoComplete="current-password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </CInputGroup>
                      {error && <div className="text-danger">{error}</div>}
                      <CRow>
                        <CCol xs={6}>
                          <CButton type="submit" color="primary" className="px-4">
                            Login
                          </CButton>
                        </CCol>
                      </CRow>
                    </CForm>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    </>
  )
}

export default Login
