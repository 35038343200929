import React from 'react'
import {
  CAvatar,
  CDropdown,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react'
import { cilLockLocked } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import avatar8 from './../../assets/images/avatars/user-profile.jpg'
import { useNavigate } from 'react-router-dom'
const AppHeaderDropdown = () => {
  const navigate = useNavigate()
  const user = JSON.parse(localStorage.getItem('user'))
  const handleLogout = () => {
    // Remove token from local storage
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    navigate(`/login`)
    // Redirect to login page or perform any other actions
    // window.location.href = '/login' // Redirect to login page
  }

  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
        <div className="d-flex align-items-center">
          <CAvatar src={avatar8} size="md" className="me-2" />
          <div>
            <div className="fw-bold">{user && user.name ? user.name : ''}</div>
            <div className="text-muted">{user && user.role ? user.role.toUpperCase() : ''}</div>
          </div>
        </div>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownHeader className="bg-light fw-semibold py-2">Account</CDropdownHeader>
        <CDropdownItem onClick={handleLogout}>
          <CIcon icon={cilLockLocked} className="me-2" />
          Logout
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}

export default AppHeaderDropdown
