import React from 'react'
const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
const Users = React.lazy(() => import('./views/users/Users'))
const Houses = React.lazy(() => import('./views/houses/Houses'))
const CreateHouses = React.lazy(() => import('./views/houses/CreateHouses'))
const EditHouses = React.lazy(() => import('./views/houses/EditHouses'))
const SingleHouse = React.lazy(() => import('./views/houses/SingleHouse'))
const CreateUser = React.lazy(() => import('./views/users/CreateUser'))
const EditUser = React.lazy(() => import('./views/users/EditUser'))
const Challenges = React.lazy(() => import('./views/challenges/Challenges'))
const CreateChallenges = React.lazy(() => import('./views/challenges/CreateChallenges'))
const EditChallenges = React.lazy(() => import('./views/challenges/EditChallenges'))
const UsersChallenges = React.lazy(() => import('./views/users/UsersChallenges'))
const Messages = React.lazy(() => import('./views/messages/Messages'))
const Keywords = React.lazy(() => import('./views/urgentCases/KeyWords'))
const Cases = React.lazy(() => import('./views/urgentCases/Cases'))
const CaseMessages = React.lazy(() => import('./views/urgentCases/CaseMessages'))
const DailyCheckIns = React.lazy(() => import('./views/dailyCheckIns/DailyCheckIns'))
const MoodRings = React.lazy(() => import('./views/moodRings/MoodRings'))
const CreateMoodRings = React.lazy(() => import('./views/moodRings/CreateRings'))
const EditRings = React.lazy(() => import('./views/moodRings/EditRings'))
const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/dashboard', name: 'Dashboard', element: Dashboard },
  { path: '/users', name: 'Users', element: Users },
  { path: '/users/create', name: 'Add Users', element: CreateUser },
  { path: '/users/edit/:id', name: 'Edit Users', element: EditUser },
  { path: '/houses', name: 'Houses', element: Houses },
  { path: '/houses/create', name: 'Add Houses', element: CreateHouses },
  { path: '/houses/edit/:id', name: 'Edit Houses', element: EditHouses },
  { path: '/houses/:id', name: 'House Details', element: SingleHouse },
  { path: '/challenges', name: 'Challenges', element: Challenges },
  { path: '/challenges/create/:id', name: 'Add Challenges', element: CreateChallenges },
  { path: '/challenges/edit/:id', name: 'Edit Challenges', element: EditChallenges },
  { path: '/users/:id/challenges', name: "User's Challenges", element: UsersChallenges },
  { path: '/messages', name: 'Messages', element: Messages },
  { path: '/keywords', name: 'Key Words', element: Keywords },
  { path: '/cases', name: 'Cases', element: Cases },
  { path: '/cases/:id', name: 'Case Messages', element: CaseMessages },
  { path: '/checkins/:id', name: 'Daily Check-Ins', element: DailyCheckIns },
  { path: '/moodrings', name: 'Mood Rings', element: MoodRings },
  { path: '/moodrings/create', name: 'Create Mood Rings', element: CreateMoodRings },
  { path: '/moodrings/edit/:id', name: 'Edit Mood Rings', element: EditRings },
]

export default routes
