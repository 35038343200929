import React from 'react'
import CIcon from '@coreui/icons-react'
import { cilSpeedometer } from '@coreui/icons'
import { CNavItem, CNavTitle, CNavGroup } from '@coreui/react'
import withTokenValidation from './withTokenValidation'

const _nav = [
  {
    component: withTokenValidation(CNavItem),
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    // badge: {
    //   color: 'info',
    //   text: 'NEW',
    // },
  },
  {
    component: withTokenValidation(CNavItem),
    name: 'Users',
    to: '/users',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },
  {
    component: withTokenValidation(CNavItem),
    name: 'Houses',
    to: '/houses',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },
  {
    component: withTokenValidation(CNavItem),
    name: 'Challenges',
    to: '/challenges',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },
  {
    component: withTokenValidation(CNavItem),
    name: 'Messages',
    to: '/messages',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },
  {
    component: withTokenValidation(CNavGroup),
    name: 'Urgent Cases',
    // to: '/urgent_cases',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    items: [
      {
        component: withTokenValidation(CNavItem),
        name: 'Cases',
        to: '/cases',
      },
      {
        component: withTokenValidation(CNavItem),
        name: 'Key Words',
        to: '/keywords',
      },
    ],
  },
  {
    component: withTokenValidation(CNavItem),
    name: 'Mood Rings',
    to: '/moodrings',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
  },
]

export default _nav
