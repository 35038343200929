import React from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import withTokenValidation from './withTokenValidation' // Import the HOC

// Components
import DefaultLayout from './layout/DefaultLayout'
import Login from './views/pages/login/Login'
import Register from './views/pages/register/Register'
import Page404 from './views/pages/page404/Page404'
import Page500 from './views/pages/page500/Page500'

const App = () => {
  const ProtectedDefaultLayout = withTokenValidation(DefaultLayout) // Wrap DefaultLayout with the HOC

  return (
    <HashRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/404" element={<Page404 />} />
        <Route path="/500" element={<Page500 />} />
        {/* Wrap ProtectedDefaultLayout with the HOC */}
        <Route path="*" element={<ProtectedDefaultLayout />} />
      </Routes>
    </HashRouter>
  )
}

export default App
